export const useSendingForm = () => {
  const sendingForm = ref(false)
  const setSendingForm = (value: boolean) => {
    sendingForm.value = value
  }
  return {
    sendingForm,
    setSendingForm,
  }
}
